<template>
  <div class="rank">
    <div class="rank_content">
      <div class="rank_content_wrapper">
        <div class="img">
          <div class="img_content" v-show="isShow">
            <img class="img_wrong"  src="../../image/icon_wrong.png" alt="">
            <p class="img_checkinInner">签到失败</p>
            <button class="img_checkin_button" @click="goBack">返回</button>
          </div>
          <div class="img_content" v-show="!isShow">
            <img class="img_correct"  src="../../image/icon_correct.png" alt="">
            <p class="img_checkinInner">签到成功</p>
          </div>
        </div>
      </div>
      <div class="checkin_staff_info" v-show="!isShow">
        <li><span>姓名</span><p>{{rankInfo.my_record.name}}</p></li>
        <li><span>签到地点</span><p>{{ rankInfo.my_record.place }}</p></li>
        <li><span>签到时间</span><p>{{ rankInfo.my_record.checkin_time}}</p></li>
      </div>
      <div class="checkin_info">
        <span>
          您已完成签到，感谢您对本次活动的大力支持。<br>
        请与现场工作人员确认是否需手签，
        如未按要求完成现场手签将可能被取消本次活动参与。
        </span>

      </div>
      <div class="info_warn">
        <p>如有问题请联系：<a href="mailto:leqiliao@imprforest.com">leqiliao@imprforest.com</a></p>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "layout",
  data(){
    return{
      isShow:false,
    }
  },
  computed:{
    ...mapState({
      rankInfo:state => state.register.rankInfo
    }),
  },
  mounted(){
    this.pageTitle('')
    this.verifyCheckin().then(()=>{
        this.getRank().then(()=>{

        }).catch(e=>{

        })
    }).catch(e=>{

    })
  },
  methods:{
    ...mapActions({
      pageTitle:'register/pageTitle',
      verifyCheckin: 'register/verifyCheckin',
      getRank:'register/getRank'
    }),
    goBack(){
      this.$router.replace({name:'checkin'})
    },
  }

}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@import '../../styles/mixins.styl'
ul,li
  list-style-type none
  padding 0
  margin 0
.rank
  padding 0 38px
  background white
  .rank_content
    .rank_content_wrapper
      display flex
      flex-direction column
      align-items center
      //border-bottom 2px solid #CCCCCC
      .img
        .img_content
          display flex
          flex-direction column
          align-items center
          img
            width 150px
            border-radius 50%
        .img_wrong
          background $error
        .img_correct
          background $theme
        .img_checkinInner
          font-size 72px
          fontSet()
          color #333333
          font-weight bold
          line-height 69px
          margin  0
          padding 30px 0 60px
        .checkinClass
          color $theme
        .img_checkin_button
          buttonSet()
          width 288px
          color white
          margin-bottom 30px
      .rank_content_button
        buttonSet()
        width 100%
        margin 30px 0
        color white

  .checkin_staff_info
    width 670px
    //height 65px
    background #F5F5F5
    border-radius 10px
    display flex
    flex-direction column
    justify-content space-between
    padding 25px 0 25px 37px
    margin-bottom 10px
    box-sizing border-box
    li
      display flex
      flex-direction row
      //padding-bottom 10px
      padding 10px 0 10px
      span
        width 5em
        infoSpanSet()
        text-align: justify;
        text-align-last: justify;
        padding-right 28px
        line-height 34px
      p
        padding 0
        margin 0
        infoPSet()
        font-size 36px
        line-height 34px
  .checkin_info
    padding 30px 10px 50px
    font-size 30px
  .info_warn
    border-top  2px solid #CCCCCC
    p
      infoSpanSet()
      font-size 24px
      text-align center
</style>
